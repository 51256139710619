import { TextInput } from '@deltablue/components';
import { createMemo, For, type VoidComponent } from 'solid-js';
import { useProjectPrice } from '../context/ProjectPrice';
import { totalDaysPerMonth, totalHoursPerDay } from '../values';
import { AddButton } from './AddButton';
import { CalcBox } from './CalcBox';
import { CalcLine } from './CalcLine';
import { ContainerPrice } from './ContainerPrice';
import { EdgeGatewaysPrice } from './EdgeGatewaysPrice';
import { Flex } from './Flex';
import { NameField } from './NameField';
import { RemoveButton } from './RemoveButton';
import { VariablePriceLine } from './VariablePriceLine';
import { validateNumberInput } from '../util/validation';

type Props = {
    index: number;
};

export const EnvironmentPrice: VoidComponent<Props> = p => {
    const [data, price, { updateEnvironment, removeEnvironment, addContainer }] = useProjectPrice();

    const envData = createMemo(() => data.environments[p.index]);
    const envPrice = createMemo(() => price().environments[p.index]);

    return (
        <CalcBox>
            <Flex style={{ width: '24rem' }} direction='column' align='stretch' gutter='1.5rem'>
                <Flex direction='row' align='center' gutter='1rem'>
                    <NameField
                        value={envData().name}
                        onInput={e => updateEnvironment(p.index, { name: e.target.value })}
                        placeholder='environment name'
                    />
                    <RemoveButton
                        onClick={() => removeEnvironment(p.index)}
                    />
                </Flex>
                <Flex direction='column' align='stretch' gutter='0.5rem'>
                    <Flex direction='column' align='stretch' gutter='0.5rem'>
                        <For each={envData().containers}>
                            {(_, i) => (
                                <ContainerPrice
                                    environmentIndex={p.index}
                                    index={i()}
                                />
                            )}
                        </For>
                        <AddButton
                            color='var(--lc-brand)'
                            onClick={() => addContainer(p.index)}
                        >
                            add stack item
                        </AddButton>
                        <EdgeGatewaysPrice
                            environmentIndex={p.index}
                        />
                    </Flex >
                    <CalcLine>+</CalcLine>
                    <Flex direction='column' align='stretch' gutter='1rem'>
                        <VariablePriceLine>
                            {{
                                label: 'Full-time running monthly cost',
                                subTotal: (
                                    <span style={{ 'font-family': 'var(--lf-mono)' }}>
                                        &euro; {envPrice().unoptimizedPriceEur.toFixed(2)}
                                    </span >
                                ),
                            }}
                        </VariablePriceLine>
                        <Flex direction='column' align='stretch' gutter='0.25rem'>
                            <VariablePriceLine>
                                {{
                                    label: <div style={{ width: '6rem' }}>hours per day</div>,
                                    input: (
                                        <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '4rem' }}>
                                            <TextInput
                                                type='number'
                                                min='1'
                                                max={totalHoursPerDay}
                                                value={envData().hoursPerDay}
                                                onChange={e => {
                                                    const value = validateNumberInput(e);
                                                    if (value !== undefined) updateEnvironment(p.index, { hoursPerDay: value });
                                                }}
                                            />
                                        </div>
                                    ),
                                    unitPrice: 'hours',
                                    unit: 'day',
                                }}
                            </VariablePriceLine>
                            <VariablePriceLine>
                                {{
                                    label: <div style={{ width: '6rem' }}>days per month</div>,
                                    input: (
                                        <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '4rem' }}>
                                            <TextInput
                                                type='number'
                                                min='1'
                                                max={totalDaysPerMonth}
                                                value={envData().daysPerMonth}
                                                onChange={e => {
                                                    const value = validateNumberInput(e);
                                                    if (value !== undefined) updateEnvironment(p.index, { daysPerMonth: value });
                                                }}
                                            />
                                        </div>
                                    ),
                                    unitPrice: 'days',
                                    unit: 'month',
                                }}
                            </VariablePriceLine>
                        </Flex>
                    </Flex>
                    <CalcLine>×</CalcLine>
                    <VariablePriceLine>
                        {{
                            label: 'Optimized monthly cost',
                            total: <span>€ {envPrice().priceEur.toFixed(2)}</span>
                        }}
                    </VariablePriceLine>
                </Flex>
            </Flex>
        </CalcBox>
    );
};
