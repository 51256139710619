import { ComponentProps, splitProps, type ParentComponent } from 'solid-js';

type Props = {
    vertical?: boolean;
} & ComponentProps<'button'>;

export const AddButton: ParentComponent<Props> = p => {
    const [local, others] = splitProps(p, ['children', 'vertical']);
    return (
        <button {...others} style={{
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            appearance: 'none',
            'background-color': 'var(--lc-highlight)',
            padding: '0.125rem',
            'padding-inline': '1.5rem',
            'text-align': 'center',
            'border-radius': '0.25rem',
            border: 'none',
            color: 'var(--lc-primaryContrast)',
            'font-size': '0.875rem',
            'font-family': 'var(--lf-main)',
            cursor: 'pointer',
            outline: 'none',
            gap: '0.5rem',
            'text-transform': 'uppercase',
            ...(local.vertical ? {
                'writing-mode': 'vertical-rl',
                'text-orientation': 'mixed',
            } : {}),
        }}>
            <span style={{ color: '--var(positive)', 'font-size': '1.75rem' }}>+&nbsp;</span>
            {local.children}
        </button>
    );
}
