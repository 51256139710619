import { type Component, type JSXElement } from 'solid-js';
import { Flex } from './Flex';

type Props = {
    children: JSXElement;
};

export const CalcBox: Component<Props> = p => {
    return (
        <Flex
            direction='column'
            gutter='0.5rem'
            style={{ border: '1px solid var(--lc-primary)', color: 'var(--lc-primary)', overflow: 'visible', padding: '1rem', 'border-radius': '0.25rem' }}
        >
            {p.children}
        </Flex>
    )
}
