import { children, JSXElement, Show, type Component } from 'solid-js';
import { Flex } from './Flex';

type Props = {
    children: {
        label: JSXElement;
        unitPrice?: JSXElement;
        unit?: string;
        input?: JSXElement;
        subTotal?: JSXElement;
        total?: JSXElement;
    };
};

export const VariablePriceLine: Component<Props> = p => {
    const unitPrice = children(() => p.children.unitPrice);
    const unit = children(() => p.children.unit);
    const input = children(() => p.children.input);
    const subTotal = children(() => p.children.subTotal);
    const total = children(() => p.children.total);
    return (
        <Flex direction='row' align='center' gutter='1rem'>
            <div style={{
                'flex-grow': 1,
                'font-size': total() ? '1.25rem' : subTotal() ? '1rem' : '0.75rem',
                'font-family': 'var(--lf-main)',
                'font-weight': (total() || subTotal()) ? 700 : 400,
            }}>
                {p.children.label}
            </div>
            <Show when={unitPrice()}>
                <span style={{ 'font-family': 'var(--lf-mono)', 'white-space': 'nowrap' }}>
                    {unitPrice()}
                </span>
                <span style={{ 'font-size': '1rem', opacity: 0.5 }}>
                    /
                </span>
            </Show>
            <Show when={unit()}>
                <div style={{ 'font-family': 'var(--lf-mono)', 'font-size': '0.875rem', 'flex-grow': 0 }}>
                    {unit()}
                </div>
                <span style={{ 'font-size': '1rem', opacity: 0.5 }}>
                    &times;
                </span>
            </Show>
            <Show when={input()}>
                <div>
                    {input()}
                </div>
            </Show>
            <Show when={subTotal()}>
                <div style={{ 'font-size': '1rem', 'font-family': 'var(--lf-mono)', 'font-weight': 700, 'white-space': 'nowrap' }}>
                    {subTotal()}
                </div >
            </Show>
            <Show when={total()}>
                <div style={{ 'font-size': '1.25rem', 'font-family': 'var(--lf-mono)', 'font-weight': 700, 'white-space': 'nowrap' }}>
                    {total()}
                </div >
            </Show>
        </Flex>
    );
};
