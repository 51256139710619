import { For, type VoidComponent } from 'solid-js';
import { useProjectPrice } from '../context/ProjectPrice';
import { AddButton } from './AddButton';
import { EnvironmentPrice } from './EnvironmentPrice';
import { Flex } from './Flex';

export const ProjectPrice: VoidComponent = () => {
    const [data, price, { addEnvironment }] = useProjectPrice();

    return (
        <div>
            <div style={{ overflow: 'auto' }}>
                {/* CURSED: this styling is coupled to the website */}
                <div style={{ 'width': 'min-content', 'padding-inline': 'calc(max(((100vw - var(--maxContentWidth)) / 2), 0px) + var(--minDistanceFromScreenEdge))', 'padding-bottom': '1rem', 'margin-bottom': '1.5rem' }}>
                    <div style={{ display: 'flex', 'align-items': 'stretch', gap: '1rem' }}>
                        <For each={data.environments}>
                            {(_, i) => (
                                <EnvironmentPrice
                                    index={i()}
                                />
                            )}
                        </For>
                        <AddButton
                            color='var(--lc-brand)'
                            vertical
                            onClick={addEnvironment}
                        >
                            add environment
                        </AddButton>
                    </div>
                </div>
            </div>
            <div style={{ position: 'sticky', bottom: 0, 'background-color': 'var(--lc-primaryOverlay)', 'backdrop-filter': 'blur(var(--overlayBlur))', '-webkit-backdrop-filter': 'blur(var(--overlayBlur))', 'align-items': 'end', gap: '0.5rem' }}>
                <Flex direction='row' justify='start' gutter='1rem' style={{ 'max-width': 'var(--maxContentWidth)', 'padding-inline': 'var(--minDistanceFromScreenEdge)', 'padding-block': '1rem', margin: '0 auto' }}>
                    <span style={{ 'font-size': '1.25rem', 'font-family': 'var(--lf-main)' }}>Total project cost per month</span>
                    <div style={{ 'padding-block': '0.5rem', 'padding-inline': '1rem', 'background-color': 'var(--lc-subtle-3)', 'border-radius': '0.25rem' }}>
                        <strong style={{ 'font-size': '1.5rem', 'font-weight': 700, 'font-family': 'var(--lf-mono)' }}>€ {price().priceEur.toFixed(2)}</strong>
                    </div>
                </Flex>
            </div>
        </div>
    );
};
