import { type ParentComponent } from 'solid-js';
import { Flex } from './Flex';

export const CalcLine: ParentComponent = p => {
    return (
        <Flex direction='row' align='center' gutter='1rem'>
            <Flex grow={1} style={{ height: '1px', 'background-color': 'var(--lc-primary)' }} />
            <div style={{
                color: 'var(--lc-primary)',
                'font-size': '1.25rem',
                'font-weight': 500,
                'line-height': 0.75,
                'align-self': 'flex-end',
                'text-align': 'right',
                'margin-right': '0.25rem',
                'margin-block': '0.25rem',
            }}>
                {p.children}
            </div>
        </Flex>
    );
};
