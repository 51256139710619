import { TextInput } from '@deltablue/components';
import { createMemo, type VoidComponent } from 'solid-js';
import { useProjectPrice } from '../context/ProjectPrice';
import { corePriceEur, memoryGiBPriceEur, storageGiBPriceEur } from '../values';
import { CalcBox } from './CalcBox';
import { CalcLine } from './CalcLine';
import { Flex } from './Flex';
import { NameField } from './NameField';
import { RemoveButton } from './RemoveButton';
import { VariablePriceLine } from './VariablePriceLine';
import { validateNumberInput } from '../util/validation';

const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

type Props = {
    environmentIndex: number;
    index: number;
};

export const ContainerPrice: VoidComponent<Props> = p => {
    const [data, price, { updateContainer, removeContainer }] = useProjectPrice();

    const contData = createMemo(() => data.environments[p.environmentIndex].containers[p.index]);
    const contPrice = createMemo(() => price().environments[p.environmentIndex].containers[p.index]);

    return (
        <CalcBox>
            <Flex style={{ position: 'relative' }} grow={1} direction='column' align='stretch' gutter='0.25rem'>
                <Flex direction='row' gutter='1rem' align='center' >
                    <NameField
                        value={contData().name}
                        onChange={e => updateContainer(p.environmentIndex, p.index, { name: e.target.value })}
                        placeholder='container name'
                        style={{ 'flex-grow': 1 }}
                    />
                    <RemoveButton
                        onClick={() => removeContainer(p.environmentIndex, p.index)}
                    />
                </Flex >
                <VariablePriceLine>
                    {{
                        label: 'processor',
                        unitPrice: <span>&euro; {corePriceEur}</span>,
                        input: (
                            <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '6rem' }}>
                                <TextInput
                                    type='number'
                                    min='1'
                                    max='256'
                                    step='1'
                                    value={contData().cores}
                                    onChange={e => {
                                        const value = validateNumberInput(e);
                                        if (value !== undefined) updateContainer(p.environmentIndex, p.index, { cores: value });
                                    }}
                                />
                            </div>
                        ),
                        unit: 'core'
                    }}
                </VariablePriceLine>
                <VariablePriceLine>
                    {{
                        label: 'memory',
                        input: (
                            <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '6rem' }}>
                                <TextInput
                                    type='number'
                                    min='0.5'
                                    max='256'
                                    step='0.5'
                                    value={contData().memoryGiB}
                                    onChange={e => {
                                        const value = validateNumberInput(e);
                                        if (value !== undefined) updateContainer(p.environmentIndex, p.index, { memoryGiB: value });
                                    }}
                                />
                            </div>
                        ),
                        unit: 'GiB',
                        unitPrice: <span>&euro; {memoryGiBPriceEur.toLocaleString(undefined, formatOptions)}</span>,
                    }}
                </VariablePriceLine>
                <VariablePriceLine>
                    {{
                        label: 'storage',
                        input: (
                            <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '6rem' }}>
                                <TextInput
                                    type='number'
                                    min='3'
                                    max='2048'
                                    step='0.5'
                                    value={contData().storageGiB}
                                    onChange={e => {
                                        const value = validateNumberInput(e);
                                        if (value !== undefined) updateContainer(p.environmentIndex, p.index, { storageGiB: value });
                                    }}
                                />
                            </div>
                        ),
                        unit: 'GiB',
                        unitPrice: <span>&euro; {storageGiBPriceEur.toLocaleString(undefined, formatOptions)}</span>,
                    }}
                </VariablePriceLine>
                <CalcLine>+</CalcLine>
                <VariablePriceLine>
                    {{
                        label: 'redundancy',
                        input: (
                            <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '6rem' }}>
                                <TextInput
                                    type='number'
                                    min='1'
                                    max='12'
                                    step='1'
                                    value={contData().amount}
                                    onChange={e => {
                                        const value = validateNumberInput(e);
                                        if (value !== undefined) updateContainer(p.environmentIndex, p.index, { amount: value });
                                    }}
                                />
                            </div>
                        ),
                        unit: 'instances',
                    }}
                </VariablePriceLine>
                <CalcLine>×</CalcLine>
                <VariablePriceLine>
                    {{
                        label: 'Stack item monthly running cost',
                        subTotal: <span>€ {contPrice().priceEur.toFixed(2)}</span>,
                    }}
                </VariablePriceLine>
            </Flex>
        </CalcBox>
    );
}
