import { createMemo, type VoidComponent } from 'solid-js';
import { useProjectPrice } from '../context/ProjectPrice';
import { Flex } from './Flex';
import { NameField } from './NameField';
import { CalcBox } from './CalcBox';
import { edgeGatewayPriceEur } from '../values';
import { TextInput } from '@deltablue/components';
import { validateNumberInput } from '../util/validation';

type Props = {
    environmentIndex: number;
};

export const EdgeGatewaysPrice: VoidComponent<Props> = p => {
    const [data, , { updateEdgeGateways }] = useProjectPrice();

    const envData = createMemo(() => data.environments[p.environmentIndex]);

    return (
        <CalcBox>
            <Flex gutter='1rem' direction='row' align='center'>
                <NameField
                    value='edge gateways'
                    placeholder='gateway name'
                    disabled
                />
                <span style={{ 'font-family': 'var(--lf-mono)', 'white-space': 'nowrap' }}>
                    &euro; {edgeGatewayPriceEur}
                </span>
                <span style={{ 'font-size': '1rem', opacity: 0.5 }}>
                    &times;
                </span>
                <div style={{ display: 'grid', 'grid-template-columns': '100%', 'place-items': 'stretch', width: '4rem' }}>
                    <TextInput
                        type='number'
                        min={1}
                        value={envData().edgeGateways.amount}
                        onInput={e => {
                            const value = validateNumberInput(e);
                            if (value !== undefined) updateEdgeGateways(p.environmentIndex, { amount: value });
                        }}
                    />
                </div>
            </Flex>
        </CalcBox >
    );
};
