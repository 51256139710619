import { JSX, splitProps, type ComponentProps, type ParentComponent } from 'solid-js';
import c from './Flex.module.css';

type Props = {
    direction?: 'column' | 'row';
    justify?: 'center' | 'stretch' | 'end' | 'start' | 'space-between';
    align?: 'center' | 'stretch' | 'end' | 'start';
    grow?: number;
    gutter?: string;
    style?: JSX.CSSProperties;
} & Omit<ComponentProps<'div'>, 'style'>;

/**
 * @deprecated Just use CSS instead.
 */
export const Flex: ParentComponent<Props> = p => {
    const [local, others] = splitProps(p, ['children', 'style', 'direction', 'justify', 'align', 'grow', 'gutter']);
    return (
        <div
            {...others}
            children={local.children}
            class={c.flex}
            style={{
                ...local.style,
                '--direction': local.direction,
                '--justify': local.justify,
                '--align': local.align,
                '--gutter': local.gutter,
                '--grow': local.grow,
            }}
        />
    );
};
