import { type VoidComponent, type ComponentProps } from 'solid-js';
import c from './NameField.module.css';

type Props = ComponentProps<'input'>;

export const NameField: VoidComponent<Props> = props => {
    return (
        <input
            {...props}
            class={c.input}
        />
    );
};
