import { type Accessor, createContext, createMemo, type ParentComponent, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { corePriceEur, edgeGatewayPriceEur, memoryGiBPriceEur, storageGiBPriceEur } from '../values';

const totalHoursPerDay = 24;
const totalDaysPerMonth = 31;

type ContainerData = {
    name: string;
    amount: number;
    cores: number;
    memoryGiB: number;
    storageGiB: number;
};

type EdgeGatewaysData = {
    amount: number;
};

type EnvironmentData = {
    name: string;
    hoursPerDay: number;
    daysPerMonth: number;
    edgeGateways: EdgeGatewaysData;
    containers: ContainerData[];
};

type ProjectData = {
    environments: EnvironmentData[];
};

type ProjectPrice = {
    priceEur: number;
    environments: {
        hoursPerDayOptimizationFactor: number;
        daysPerMonthOptimizationFactor: number;
        unoptimizedPriceEur: number;
        priceEur: number;
        edgeGateways: {
            priceEur: number;
        };
        containers: {
            priceEur: number;
            unitPriceEur: number;
        }[];
    }[];
};

export const ProjectPriceContext = createContext<undefined | [
    data: ProjectData,
    projectPrice: Accessor<ProjectPrice>,
    {
        addEnvironment: () => void;
        updateEnvironment: (environmentIndex: number, data: Partial<EnvironmentData>) => void;
        removeEnvironment: (environmentIndex: number) => void;
        updateEdgeGateways: (environmentIndex: number, data: Partial<EdgeGatewaysData>) => void;
        addContainer: (environmentIndex: number) => void;
        updateContainer: (environmentIndex: number, containerIndex: number, data: Partial<ContainerData>) => void;
        removeContainer: (environmentIndex: number, containerIndex: number) => void;
    },
]>();

export const ProjectPriceProvider: ParentComponent = p => {
    const [data, setData] = createStore<ProjectData>({
        environments: [
            {
                hoursPerDay: 8,
                daysPerMonth: 22,
                edgeGateways: {
                    amount: 1,
                },
                containers: [{
                    amount: 1,
                    cores: 1,
                    memoryGiB: 2,
                    storageGiB: 25,
                    name: 'Apache + PHP example',
                }],
                name: 'Development',
            },
            {
                hoursPerDay: 24,
                daysPerMonth: 31,
                edgeGateways: {
                    amount: 1,
                },
                containers: [{
                    amount: 1,
                    cores: 1,
                    memoryGiB: 2,
                    storageGiB: 25,
                    name: 'Apache + PHP example',
                }],
                name: 'Production',
            },
        ],
    });

    function addEnvironment() {
        setData('environments', data.environments.length, {
            hoursPerDay: 24,
            daysPerMonth: 31,
            edgeGateways: {
                amount: 1,
            },
            containers: [],
            name: 'New Environment',
        });
    }

    function updateEnvironment(index: number, data: Partial<EnvironmentData>) {
        setData('environments', index, v => ({ ...v, ...data }));
    }

    function removeEnvironment(index: number) {
        setData('environments', v => v.filter((v, i) => i !== index));
    }

    function updateEdgeGateways(envIndex: number, data: Partial<EdgeGatewaysData>) {
        setData('environments', envIndex, 'edgeGateways', v => ({ ...v, ...data }));
    }

    function addContainer(envIndex: number) {
        setData('environments', envIndex, 'containers', data.environments[envIndex].containers.length, {
            amount: 1,
            cores: 1,
            memoryGiB: 2,
            storageGiB: 25,
            name: 'New stack item',
        });
    }

    function updateContainer(envIndex: number, index: number, data: Partial<ContainerData>) {
        setData('environments', envIndex, 'containers', index, v => ({ ...v, ...data }));
    }

    function removeContainer(envIndex: number, index: number) {
        setData('environments', envIndex, 'containers', v => v.filter((v, i) => i !== index));
    }

    const price = createMemo(() => {
        const pricedEnvironments = data.environments.map(e => {
            const daysPerMonthOptimizationFactor = e.daysPerMonth / totalDaysPerMonth;
            const hoursPerDayOptimizationFactor = e.hoursPerDay / totalHoursPerDay;
            const totalEdgeGatewayPrice = e.edgeGateways.amount * edgeGatewayPriceEur;
            const pricedContainers = e.containers.map(c => {
                let unitPriceEur = c.cores * corePriceEur + c.memoryGiB * memoryGiBPriceEur + c.storageGiB * storageGiBPriceEur;
                return {
                    unitPriceEur,
                    priceEur: c.amount * unitPriceEur,
                };
            });
            const containersTotalPrice = pricedContainers.reduce((total, { priceEur }) => total + priceEur, 0);
            const unoptimizedPriceEur = (totalEdgeGatewayPrice + containersTotalPrice);
            return {
                daysPerMonthOptimizationFactor,
                hoursPerDayOptimizationFactor,
                unoptimizedPriceEur,
                priceEur: unoptimizedPriceEur * daysPerMonthOptimizationFactor * hoursPerDayOptimizationFactor,
                containers: pricedContainers,
                edgeGateways: {
                    priceEur: totalEdgeGatewayPrice,
                },
            };
        });
        const environmentsTotalPrice = pricedEnvironments.reduce((total, { priceEur }) => total + priceEur, 0);
        return { priceEur: environmentsTotalPrice, environments: pricedEnvironments };
    });

    return (
        <ProjectPriceContext.Provider value={[
            data,
            price,
            {
                addEnvironment,
                updateEnvironment,
                removeEnvironment,
                updateEdgeGateways,
                addContainer,
                updateContainer,
                removeContainer,
            }]}
        >
            {p.children}
        </ProjectPriceContext.Provider>
    )
}

export function useProjectPrice() {
    const ctx = useContext(ProjectPriceContext);
    if (!ctx)
        throw new Error('Not in project price context.');
    return ctx;
}
