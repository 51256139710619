import { children, Show, splitProps, type ComponentProps, type ParentComponent } from 'solid-js';

type Props = Omit<ComponentProps<'button'>, 'style'>;

export const RemoveButton: ParentComponent<Props> = p => {
    const [local, other] = splitProps(p, ['children']);
    const resolved = children(() => local.children);
    return (
        <button
            style={{
                height: '1.25rem',
                width: '1.25rem',
                'font-size': '0.875rem',
                'line-height': '1.25rem',
                'text-align': 'center',
                'flex-shrink': 0,
                border: 'none',
                margin: 0,
                padding: 0,
                'border-radius': '0.25rem',
                'background-color': 'var(--lc-warning)',
                color: 'var(--lc-primaryContrast)',
                cursor: 'pointer',
            }}
            {...other}
        >
            <Show
                when={resolved()}
                fallback={
                    <span style={{ 'line-height': 0 }}>&times;</span>
                }
            >
                {c => c()}
            </Show>
        </button>
    );
};
