export function validateNumberInput(e: { target: HTMLInputElement }): number | undefined {
    const value = parseFloat(e.target.value);
    if (Number.isNaN(value))
        return;
    if (e.target.checkValidity())
        return value;
    let min = parseFloat(e.target.min);
    if (Number.isNaN(min))
        min = 0;
    let max = parseFloat(e.target.max);
    if (Number.isNaN(max))
        max = Infinity;
    let clamped = value;
    clamped = Math.max(clamped, min);
    clamped = Math.min(clamped, max);
    e.target.value = clamped.toString();
    // if not valid, it must be a step mismatch: use built-in browser logic to clamp to previous step value
    if (!e.target.checkValidity())
        e.target.stepDown();
    // BUG: Safari does not properly clamp to min+n*step like it should according to the spec
    // revert if still not valid after stepDown
    if (!e.target.checkValidity())
        e.target.stepUp();
    e.target.reportValidity();
    return parseFloat(e.target.value);
}
